import React from "react";
import { Link } from "react-router-dom";

export default function Blog() {
  return (
    <div className="flex flex-col items-center min-h-screen bg-black p-4 md:p-8">
      <h1 className="text-3xl md:text-4xl font-poppins text-white mb-8 md:mb-12">
        MINDLR'S Blogs
      </h1>
      <div className="flex flex-col md:flex-row justify-around items-center w-full">
        <div className="w-full md:w-1/3 p-4">
          <Link to="/blog1">
            <div className="bg-white h-48 md:h-64 flex items-center justify-center text-2xl font-bold text-gray-800 border-2 border-gray-400 rounded-lg shadow-md">
              <img
                src="/images/blogpicnew1.webp"
                alt="Blog 1"
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
          </Link>
          <Link
            to="/blog1"
            className="block mt-4 text-center text-lg md:text-xl font-poppins hover:underline text-white"
          >
            AI Integration in the Workforce
          </Link>
        </div>
        <div className="w-full md:w-1/3 p-4 mt-4 md:mt-0">
          <Link to="/blog2">
            <div className="bg-white h-48 md:h-64 flex items-center justify-center text-2xl font-bold text-gray-800 border-2 border-gray-400 rounded-lg shadow-md">
              <img
                src="/images/blogpicnew.jpg"
                alt="Blog 2"
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
          </Link>
          <Link
            to="/blog2"
            className="block mt-4 text-center text-lg md:text-xl font-poppins hover:underline text-white"
          >
            AI Tool Discovery Made Simple
          </Link>
        </div>
      </div>
    </div>
  );
}
