import React, { useRef, useState } from "react";
import ParticlesAnimation from "../components/ParticlesAnimation";
import ContactForm from "../components/ContactForm";
import ShimmerButton from "../components/ShimmerButton";
import { FaTimes, FaRegPlayCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom"; // Import useHistory for navigation
import { GoArrowRight } from "react-icons/go";
import { RiNumber1, RiNumber2, RiNumber3 } from "react-icons/ri";

export default function Home() {
  const contactFormRef = useRef(null);
  const videoRef = useRef(null);
  const [isVideoExpanded, setIsVideoExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate(); // Initialize useHistory

  const scrollToContactForm = () => {
    contactFormRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const toggleVideoExpansion = (e) => {
    e.stopPropagation();
    setIsVideoExpanded(!isVideoExpanded);
    if (videoRef.current) {
      if (!isVideoExpanded) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      navigate("/sign-in");
    }
  };

  return (
    <div className="relative min-h-screen overflow-hidden bg-black">
      {/* New Section with Text Background and Search Bar */}
      <div className="relative flex items-center justify-center min-h-screen bg-black">
        {/* Text above the Search Bar */}
        <div className="absolute top-1/4 text-center z-10">
          <h1 className="text-white text-4xl md:text-5xl font-poppins w-auto">
            Discover AI tools to automate your tasks with ease
          </h1>
        </div>

        {/* Search Bar */}
        <div className="relative z-10 w-full mt-10 md:mt-0 max-w-6xl px-8">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                navigate("/sign-in");
              }
            }}
            placeholder="Find an automation for your situation/task..."
            className="w-4/5  py-4 px-4 text-white bg-gray-800 bg-opacity-50 rounded-full text-sm md:text-2xl mx-auto block"
          />
        </div>

        <style>
          {`
      @keyframes float {
        0% {
          transform: translateY(0);
        }
        50% {
          transform: translateY(-10px);
        }
        100% {
          transform: translateY(0);
        }
      }
    `}
        </style>
      </div>

      <div className="relative z-10 flex flex-col justify-center min-h-screen px-4 md:px-8 lg:px-12 mt-2 space-y-4 w-full max-w-7xl mx-auto">
        <div className="w-full text-left">
          <h2 className="text-white font-poppins text-4xl md:text-5xl tracking-wide w-full leading-tight">
            We believe every challenge has an AI solution.
          </h2>
          <h4 className="text-white mt-4 text-2xl md:text-3xl font-poppins tracking-wider w-full text-center">
            Our mission is to help you find it.
          </h4>
        </div>

        <div className="w-full flex justify-center mt-8">
          <div
            className={`bg-transparent rounded-lg ${
              isVideoExpanded
                ? "fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                : "h-[28rem] w-full max-w-2xl"
            } relative`}
            onClick={!isVideoExpanded ? toggleVideoExpansion : undefined}
          >
            {!isVideoExpanded && (
              <>
                <img
                  src="/images/thumbnail.png"
                  alt="Video Thumbnail"
                  className="object-contain cursor-pointer bg-black w-full h-full"
                  onClick={toggleVideoExpansion}
                />
                <FaRegPlayCircle
                  className="text-textcolor text-8xl absolute inset-0 m-auto cursor-pointer"
                  onClick={toggleVideoExpansion}
                />
              </>
            )}
            {isVideoExpanded && (
              <>
                <video
                  ref={videoRef}
                  className="fixed inset-0 w-auto h-auto max-w-full max-h-full z-50 m-auto"
                  controls
                  src="/images/new_website_vid.mp4"
                  autoPlay
                />
                <button
                  className="absolute top-[-6rem] right-0 text-lg bg-black text-textcolor p-2 rounded-full z-50 md:top-[-22rem] md:right-4"
                  onClick={toggleVideoExpansion}
                >
                  <FaTimes />
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="w-full px-8 md:px-16 lg:px-32 mt-16">
        <h2 className="text-6xl font-poppins text-textcolor mb-6 px-6">
          Why Mindlr?
        </h2>
        <div className="flex flex-wrap justify-between space-y-4 md:space-y-0 md:space-x-8">
          <div className="w-full md:flex-1 p-4">
            <div className="flex items-center mb-4">
              <RiNumber1 className="text-7xl text-textcolor text-bold font-poppins mr-4" />
              <h3 className="text-2xl font-poppins text-textcolor">
                Personalized AI Tool Recommendations
              </h3>
            </div>
            <p className="text-xl font-poppins text-textcolor">
              Get AI tool suggestions that match your specific requirements and
              workflow.
            </p>
          </div>
          <div className="w-full md:flex-1 p-4">
            <div className="flex items-center mb-4">
              <RiNumber2 className="text-7xl text-textcolor text-bold font-poppins mr-4" />
              <h3 className="text-2xl font-poppins text-textcolor">
                Advanced Video Search and Retrieval
              </h3>
            </div>
            <p className="text-xl text-textcolor font-poppins">
              Our algorithm and technology make it easy to find the content you
              need, allowing you to search for any instance.
            </p>
          </div>
          <div className="w-full md:flex-1 p-4">
            <div className="flex items-center mb-4">
              <RiNumber3 className="text-7xl text-textcolor text-bold font-poppins mr-4" />
              <h3 className="text-2xl font-poppins text-textcolor">
                User-Generated Content and Insights
              </h3>
            </div>
            <p className="text-xl text-textcolor font-poppins">
              Tap into a wealth of knowledge from the real users of the tools,
              sharing their insights and experiences with AI tools.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-center mt-80 mb-60">
        <div className="relative w-full md:w-1/3 h-64 md:h-96 flex items-center justify-center overflow-hidden mb-8 md:mb-0">
          <div
            className="absolute inset-0 border-4 border-white flex flex-col items-center justify-center"
            style={{
              borderRadius: "50%",
              clipPath: "inset(0 0 0 10%)", // Clip the left part of the left circle
            }}
          >
            <h3 className="text-textcolor text-2xl md:text-4xl font-poppins mb-2">
              AI repositories
            </h3>
            <p className="text-textcolor font-poppins text-center text-lg md:text-xl">
              Scroll through thousands of
            </p>
            <p className="text-textcolor font-poppins text-center text-lg md:text-xl">
              tools for your task
            </p>
          </div>
        </div>
        <div className="w-full md:w-1/3 text-center p-4 md:p-8 flex flex-col items-center">
          <h2 className="text-3xl md:text-5xl font-poppins font-bold text-textcolor mb-4">
            MINDLR
          </h2>
          <p className="text-textcolor font-poppins text-base md:text-2xl mb-4">
            Describe your task or situation in layman terms and get user-made
            video recommendations within seconds.
          </p>
          <div className="mt-4">
            <ShimmerButton onClick={scrollToContactForm}>
              Join beta!
            </ShimmerButton>
          </div>
        </div>
        <div className="relative w-full md:w-1/3 h-64 md:h-96 flex items-center justify-center overflow-hidden">
          <div
            className="absolute inset-0 border-4 border-white flex flex-col items-center justify-center"
            style={{
              borderRadius: "50%",
              clipPath: "inset(0 10% 0 0)", // Clip the right part of the right circle
            }}
          >
            <h3 className="text-textcolor text-2xl md:text-4xl font-poppins mb-2">
              AI chatbots
            </h3>
            <p className="text-textcolor font-poppins text-center text-lg md:text-xl">
              Get inaccurate, half-baked
            </p>
            <p className="text-textcolor font-poppins text-center text-lg md:text-xl">
              textual results
            </p>
            <p className="text-textcolor text-center font-poppins text-sm md:text-xl">
              you cannot rely on
            </p>
          </div>
        </div>
      </div>

      <div
        className="w-full max-w-7xl px-8 md:px-16 lg:px-32 mt-80 mx-auto"
        ref={contactFormRef}
      >
        <ContactForm />
      </div>
    </div>
  );
}
