import React from "react";

export default function About() {
  return (
    <div className="bg-black text-white px-4 md:px-16 lg:px-32">
      {/* First Section: Background Image with Text */}
      <div
        className="relative h-96 bg-cover bg-center"
        style={{
          width: "100vw",
          marginLeft: "calc(50% - 50vw)",
          backgroundImage: `url('/images/mindlr2.png')`,
          filter: "brightness(1.2) contrast(1.1)",
        }}
      >
        <div className="absolute inset-0 bg-black opacity-30"></div>
        <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white px-4 md:px-8 lg:px-16">
          <h1 className="text-4xl md:text-5xl font-poppins mb-4">
            About Mindlr
          </h1>
          <p className="text-base md:text-xl font-poppins max-w-lg md:max-w-3xl">
            Our story at Mindlr began with the realization that navigating the
            vast landscape of AI tools is overwhelming. We set out to create a
            platform where anyone can find and explore AI tools tailored to
            their specific needs. Our mission is to make AI accessible and
            beneficial for everyone, empowering a diverse community of
            innovators to stay ahead in the rapidly evolving world of AI.
          </p>
        </div>
      </div>

      <div className="bg-black text-white px-4 md:px-8 lg:px-16 mt-0">
        <div className="container mx-auto p-12 md:p-24">
          <div className="flex flex-col md:flex-row md:space-x-8 items-stretch h-full">
            {/* Left Div */}
            <div className="w-full md:w-1/2 pr-4 md:pr-8 flex flex-col justify-center h-full">
              <div className="relative w-full h-full flex-grow flex flex-col justify-center items-center md:items-start md:ml-40 mt-8 md:mt-20">
                <h2 className="text-4xl font-poppins text-center md:text-left">
                  Our Journey
                </h2>
              </div>
            </div>

            {/* Separator */}
            <div className="hidden md:block w-0.5 bg-white h-auto"></div>

            {/* Right Div */}
            <div className="w-full md:w-1/2 pl-4 md:pl-8 flex flex-col justify-center h-full">
              <div className="mt-8 md:mt-0">
                <p className="text-2xl font-poppins text-center md:text-left">
                  At Mindlr, our journey began with a simple idea: to
                  revolutionize the way people find and utilize AI tools. We are
                  passionate about making AI more understandable and accessible
                  for everyone. Our team's dedication and hard work have led us
                  to create a platform that simplifies the process of
                  discovering and using AI tools. We aim to make a difference by
                  providing a space where individuals can explore, learn, and
                  apply AI in their unique contexts.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-black text-white px-4 md:px-8 lg:px-16 mt-0">
        <div className="container mx-auto p-12 md:p-24">
          <div className="flex flex-col md:flex-row md:space-x-8 items-stretch h-full">
            {/* Left Div */}
            <div className="w-full md:w-1/2 pr-4 md:pr-8 flex flex-col justify-center h-full">
              <div className="relative w-full h-full flex-grow flex flex-col justify-center items-center md:items-start md:ml-40 mt-8 md:mt-20">
                <h2 className="text-4xl font-poppins text-center md:text-left">
                  Meet Our Team
                </h2>
              </div>
            </div>

            {/* Separator */}
            <div className="hidden md:block w-0.5 bg-white h-auto"></div>

            {/* Right Div */}
            <div className="w-full md:w-1/2 pl-4 md:pl-8 flex flex-col justify-center h-full">
              <div className="mt-8 md:mt-0">
                <p className="text-2xl font-poppins text-center md:text-left">
                  At Mindlr, we're a tight-knit group of AI enthusiasts and tech
                  innovators. Led by Naveen and Abhiram, our team blends
                  creative vision with technical expertise. We're committed to
                  simplifying AI tools for you, turning complex technology into
                  accessible solutions that empower your work and learning.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
