import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ShimmerButton from "../components/ShimmerButton";
import { NeonGradientCard } from "../components/NeonGradientCard";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";

export default function Register() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = { name, email, password };

    try {
      const response = await fetch("https://themindlr.com/api/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 201) {
        alert("Registration successful!");
        navigate("/sign-in");
      } else if (response.status === 409) {
        alert("User already exists. Please go to login.");
      } else {
        alert("Failed to register");
      }
    } catch (error) {
      console.error("Error registering", error);
      alert("An error occurred while registering");
    }
  };

  const handleGoogleSuccess = async (response) => {
    const token = response.credential;
    try {
      const res = await fetch("/api/google-register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token }),
      });

      if (res.status === 200) {
        alert("Google registration successful!");
        navigate("/sign-in");
      } else {
        alert("Failed to sign up with Google");
      }
    } catch (error) {
      console.error("Error signing up with Google", error);
      alert("An error occurred while signing up with Google");
    }
  };

  const handleGoogleFailure = (error) => {
    console.error("Google Sign-In Error:", error);
    alert("Failed to sign up with Google");
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <div className="flex justify-center items-center mt-12 mb-12 px-4">
        <NeonGradientCard className="w-full max-w-md p-0 bg-black">
          <form onSubmit={handleSubmit} className="px-6 py-8">
            <h2 className="text-4xl font-poppins mb-4 text-center text-black dark:text-white">
              Register
            </h2>
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block mb-2 font-poppins text-black dark:text-white"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full p-2 border border-gray-400 rounded-md bg-black text-white"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block mb-2 font-poppins text-black dark:text-white"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-2 border border-gray-400 rounded-md bg-black text-white"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block mb-2 font-poppins text-black dark:text-white"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-2 border border-gray-400 rounded-md bg-black text-white"
                required
              />
            </div>
            <div className="flex justify-center">
              <ShimmerButton className="font-poppins mx-auto md:mx-0">
                Register
              </ShimmerButton>
            </div>
            <div className="flex justify-center mt-4">
              <GoogleLogin
                onSuccess={handleGoogleSuccess}
                onFailure={handleGoogleFailure}
                cookiePolicy={"single_host_origin"}
                buttonText="Continue with Google"
              />
            </div>
            <div className="mt-4 text-center">
              <p className="text-blue-500">
                Already registered? Go to:{" "}
                <Link to="/sign-in" className="underline">
                  Sign In
                </Link>
              </p>
            </div>
          </form>
        </NeonGradientCard>
      </div>
    </GoogleOAuthProvider>
  );
}
