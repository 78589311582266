import React from "react";
import { FaLinkedin } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";

export default function Footer() {
  return (
    <footer className="bg-custom-black opacity-90 backdrop-blur-lg text-white font-poppins py-16 px-4 md:px-16 lg:px-32 mt-0">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-start md:items-center">
        <div className="mb-4 md:mb-0">
          <h2 className="text-2xl font-bold mb-6">Mindlr</h2>
          <p className="text-lg mb-4">info@themindlr.com</p>
          <p className="text-lg">Vancouver, Canada</p>
        </div>
        <div className="flex space-x-4 mt-4 md:mt-0">
          <div className="text-lg font-bold">
            <a
              href="https://www.linkedin.com/company/mindlr/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white py-2 px-4 rounded-full text-3xl"
            >
              <FaLinkedin />
            </a>
          </div>
          <div className="text-lg font-bold">
            <a
              href="https://x.com/themindlr"
              rel="noopener noreferrer"
              className="text-white py-2 px-4 rounded-full text-3xl"
            >
              <FaTwitter />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
