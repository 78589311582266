import React, { useState, useEffect } from "react";

export default function AlphaInputs() {
  const [inputs, setInputs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to fetch the inputs from the backend
  const fetchInputs = async () => {
    try {
      const response = await fetch(
        "https://themindlr.com/api/get-alpha-inputs",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setInputs(data);
      } else {
        throw new Error("Failed to fetch inputs");
      }
    } catch (error) {
      console.error("Error fetching inputs:", error);
      setError("Failed to fetch inputs. Please try again later.");
    } finally {
      setLoading(false); // Ensure loading is stopped
    }
  };

  // Fetch inputs on component mount
  useEffect(() => {
    fetchInputs();
  }, []);

  // Show loading indicator while fetching
  if (loading) {
    return <div>Loading...</div>;
  }

  // Handle errors during fetching
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-3xl font-bold mb-6">Alpha Inputs List</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {inputs.map((input) => (
          <div
            key={input._id}
            className="bg-white p-6 rounded-lg shadow-md w-80 text-center"
          >
            <h2 className="text-black text-2xl font-semibold mb-2">
              {input.name}
            </h2>
            <p className="text-black">Email: {input.email}</p>
            <p className="text-black">Feedback: {input.feedback}</p>
            <p className="text-black">
              Submitted At: {new Date(input.submittedAt).toLocaleString()}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
