import React from "react";

export default function Blog2() {
  return (
    <div className="bg-black text-white min-h-screen font-poppins flex flex-col items-center p-8">
      <h1 className="text-4xl  mb-12 text-center">
        AI Tool Discovery Made Simple
      </h1>

      <div className="w-full max-w-3xl">
        <div className="mb-8">
          <h2 className="text-2xl  mb-4 text-center">
            1. Intuitive Natural Language Search
          </h2>
          <p className="text-lg text-center font-poppins">
            Mindlr utilizes advanced Natural Language Processing, powered by
            leading open-source models. This technology understands your queries
            in context, delivering precise results that match your intent.
            Simply describe what you need, and our platform will quickly find
            the most relevant AI tools from our extensive video content
            database.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl  mb-4 text-center">
            2. Frame-by-Frame Analysis
          </h2>
          <p className="text-lg text-center font-poppins">
            We've taken video search to the next level. Mindlr doesn't just skim
            the surface – it conducts a meticulous frame-by-frame analysis of
            video content. This thorough approach ensures that even if your
            search term appears briefly, our technology will pinpoint it with
            remarkable accuracy. The result? Unparalleled precision in
            identifying relevant content, saving you valuable time and effort in
            your quest for the perfect AI tools.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl  mb-4 text-center">
            3. Harnessing Collective Intelligence
          </h2>
          <p className="text-lg text-center font-poppins">
            Mindlr taps into the power of community-driven insights to enhance
            search accuracy. Our platform enables users to contribute tags,
            annotations, and valuable context, creating a rich, collaborative
            knowledge base. This synergy of user-generated content and AI
            filtering produces a dynamic, ever-expanding repository of
            information. It's like having a constantly evolving encyclopedia of
            AI tools, curated by experts and enthusiasts alike.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl  mb-4 text-center">
            4. Smart Recommendation System
          </h2>
          <p className="text-lg text-center font-poppins">
            Our platform is designed to learn from user interactions, aiming to
            provide increasingly relevant AI tool suggestions. As you explore
            and engage with content on Mindlr, the system works to understand
            your preferences and requirements. The goal is to offer you tailored
            recommendations that align with your specific needs, streamlining
            your journey through the vast landscape of AI tools.
          </p>
        </div>

        <div className="mt-12 text-center">
          <p className="text-lg font-poppins">
            At Mindlr, we're not just improving AI tool discovery – we're
            transforming it. By combining advanced technology with user-driven
            insights, we're creating a new paradigm in how professionals find
            and utilize AI tools. Join us in this exciting journey as we
            redefine the landscape of AI tool discovery, empowering you to
            harness the full potential of artificial intelligence in your work.
          </p>
        </div>
      </div>
    </div>
  );
}
