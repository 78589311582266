import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const NotificationPopup = ({ userId, onClose, position }) => {
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await fetch(
          `https://themindlr.com/api/notifications/${userId}`
        );
        if (response.ok) {
          const data = await response.json();
          setNotifications(data);
        } else {
          console.error("Failed to fetch notifications");
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, [userId]);

  const popupStyle = {
    top: position === "down" ? "100%" : "auto",
    bottom: position === "up" ? "100%" : "auto",
    right: -80,
    zIndex: 50,
    backgroundColor: "white",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    width: "300px",
    position: "absolute",
  };

  const handlePostClick = () => {
    // Redirect to the profile page with the posts tab selected
    navigate("/profile?case=posts");
  };

  return (
    <div style={popupStyle}>
      <div className="p-4 bg-gray-500 rounded-lg font-poppins">
        <h2 className="font-bold text-lg text-black">Notifications</h2>
        <ul className="mt-2 max-h-60 overflow-y-auto">
          {notifications.length > 0 ? (
            notifications.map((notification) => (
              <li key={notification._id} className="mb-2 flex items-start">
                <Link to={`/uploader-profile/${notification.sender._id}`}>
                  <img
                    src={
                      notification.sender?.profilePic
                        ? notification.sender.profilePic
                        : "/images/finalplaceholder.png"
                    }
                    alt="Sender Profile"
                    className="w-10 h-10 rounded-full mr-6"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "/images/defaultprofpic1.jpg";
                    }}
                  />
                </Link>
                <div>
                  <p className="text-sm text-black">
                    <strong>{notification.sender?.name}</strong>{" "}
                    {notification.type === "like" && (
                      <>
                        liked your video{" "}
                        <span
                          onClick={handlePostClick}
                          className="text-black cursor-pointer underline"
                        >
                          {notification.post?.name}
                        </span>
                      </>
                    )}
                    {notification.type === "comment" && (
                      <>
                        commented on your video{" "}
                        <span
                          onClick={handlePostClick}
                          className="text-black cursor-pointer underline"
                        >
                          {notification.post?.name}
                        </span>
                      </>
                    )}
                    {notification.type === "follow" && (
                      <>started following you</>
                    )}
                  </p>
                  <p className="text-xs text-gray-500">
                    {new Date(notification.createdAt).toLocaleString()}
                  </p>
                </div>
              </li>
            ))
          ) : (
            <p>No notifications found.</p>
          )}
        </ul>
        <button onClick={onClose} className="mt-2 text-black">
          Close
        </button>
      </div>
    </div>
  );
};

export default NotificationPopup;
