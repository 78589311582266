import React from "react";
import { FaInstagram } from "react-icons/fa";

const InstagramAuth = () => {
  const CLIENT_ID = "1601707313729751"; // Replace with your actual client ID
  const REDIRECT_URI = "https://themindlr.com/profile"; // Replace with your redirect URI
  const authUrl = `https://api.instagram.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=user_profile,user_media&response_type=code`;

  return (
    <a href={authUrl}>
      <button className="text-white text-2xl font-poppins bg-slate-500 rounded-full ml-80 p-4 mr-20">
        <FaInstagram />
      </button>
    </a>
  );
};

export default InstagramAuth;
