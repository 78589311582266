import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ShimmerButton from "../components/ShimmerButton";
import { NeonGradientCard } from "../components/NeonGradientCard";

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = { email, password };

    try {
      const response = await fetch("https://themindlr.com/api/authenticate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
        credentials: "include",
      });

      if (response.status === 200) {
        const data = await response.json();
        if (data.message === "Sign in successful") {
          navigate("/home");
        }
      } else if (response.status === 201) {
        setMessage("Thank you, you have been added to the waitlist!");
        setSubmitted(true);
      } else if (response.status === 400) {
        setMessage("Invalid email or password. Please try again.");
      } else {
        setMessage("An unexpected error occurred. Please try again later.");
      }
    } catch (error) {
      console.error("Error signing in", error);
      setMessage("An error occurred while signing in. Please try again later.");
    }
  };

  return (
    <div className="flex justify-center items-center mt-12 mb-12 px-4">
      {!submitted ? (
        <NeonGradientCard className="w-full max-w-md p-0 bg-black">
          <form onSubmit={handleSubmit} className="px-6 py-8">
            <h2 className="text-4xl font-poppins mb-4 text-center text-black dark:text-white">
              Sign In
            </h2>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block mb-2 font-poppins text-black dark:text-white"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-2 border border-gray-400 rounded-md bg-black text-white"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block mb-2 font-poppins text-black dark:text-white"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-2 border border-gray-400 rounded-md bg-black text-white"
                required
              />
            </div>
            <div className="flex justify-center">
              <ShimmerButton className="font-poppins mx-auto md:mx-0">
                Sign In
              </ShimmerButton>
            </div>
          </form>
        </NeonGradientCard>
      ) : (
        <div className="text-center text-4xl font-poppins text-white dark:text-white p-4 rounded-md">
          <h4>Thank you, you have been added to the waitlist!</h4>
        </div>
      )}
    </div>
  );
}
