import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const isAuthenticated = checkAuthentication();

  if (!isAuthenticated) {
    // Redirect to sign-in page if not authenticated
    return <Navigate to="/sign-in" />;
  }

  // If authenticated, render the children (i.e., the requested page)
  return children;
};

// Utility function to check authentication status
function checkAuthentication() {
  const token = document.cookie
    .split("; ")
    .find((row) => row.startsWith("token="));
  return !!token; // Return true if token exists, otherwise false
}

export default PrivateRoute;
